"use client";

import { ReactNode } from "react";

import * as React from "react";

import { Analytics } from "@vercel/analytics/react";

import { Provider as JotaiProvider } from "jotai";

export function LayoutClient({ children }: { children: ReactNode }) {
  return (
    <>
      <JotaiProvider>{children}</JotaiProvider>
      <Analytics />
    </>
  );
}

// export const GTMPageView = (url: string) => {
//   interface PageEventProps {
//     event: string;
//     page: string;
//   }

//   const pageEvent: PageEventProps = {
//     event: "pageview",
//     page: url,
//   };
//   //@ts-ignore
//   window && window.dataLayer && window.dataLayer.push(pageEvent);
//   return pageEvent;
// };

// function PageTracker({ children }) {
//   React.useEffect(() => {
//     const handleRouteChange = (url: string) => GTMPageView(url);
//     Router.events.on("routeChangeComplete", handleRouteChange);
//     return () => {
//       Router.events.off("routeChangeComplete", handleRouteChange);
//     };
//   }, []);

//   return <>{children}</>;
// }
