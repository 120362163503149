"use client";

import Link from "next/link";
import { useState } from "react";
import EntypoMenu from "react-entypo/lib/entypo/Menu";
import { atom, useAtom } from "jotai";
import { cx as classNames } from "class-variance-authority";
import { useOnJavascript } from "../utils/useOnJavascript";
import { usePathname } from "next/navigation";

export const displayTopherState = atom(true);

export function Navbar() {
  return (
    <>
      <div className="contents md:hidden">
        <MobileNav />
      </div>
      <div className="hidden md:contents">
        <DesktopNav />
      </div>
    </>
  );
}

function useIsNavLinkActive(href) {
  const pathname = usePathname() ?? "";

  let isActive = false;
  if (href === "/") {
    if (pathname === "/") {
      isActive = true;
    }
  } else {
    isActive = pathname.startsWith(href);
  }

  return isActive;
}

function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [displayTopher] = useAtom(displayTopherState);

  return (
    <>
      <nav
        className={classNames(
          "js-block-or-none js-sticky-or-static card-shadow-light",
          "top-0 bg-white z-20 font-semibold"
        )}
      >
        <PinkLine />
        <MobileTopBar
          displayTopher={displayTopher}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <div>{isOpen && <MobileMenuOpen />}</div>
      </nav>
      <noscript>
        <div className="sticky top-0">
          <PinkLine />
        </div>
        <div className="z-20 font-semibold bg-white card-shadow-light">
          <MobileMenuOpen />
        </div>
      </noscript>
      <style>
        {`
    .js-sticky-or-static {
      position: -webkit-sticky;
      position: sticky;
    }

    .js-block-or-none {
      display: block;
    }
    `.replace(/\n/g, "")}
      </style>
      <noscript>
        <style>
          {`
    .js-sticky-or-static {
      position: static !important;
    }

    .js-block-or-none {
      display: none !important;
    }
    `.replace(/\n/g, "")}
        </style>
      </noscript>
    </>
  );
}

function PinkLine() {
  return <div className="border-t-4 border-pink-400" />;
}

function MobileTopBar({ displayTopher, isOpen, setIsOpen }) {
  const hideWhiteBlocker = useOnJavascript();

  return (
    <div className="relative flex items-stretch justify-between pl-4 text-gray-800">
      <div className="flex items-center">
        <Link
          href="/"
          passHref
          className={classNames(
            "text-lg flex items-center transition-opacity duration-500 relative",
            displayTopher ? "opacity-100" : "opacity-0"
          )}
        >
          {/* Render a white element over Topher Winward that fades out quickly, to counter the flash */}
          <div
            className={classNames(
              "w-full h-full left-0 top-0 absolute bg-white transition-opacity delay-500 duration-500",
              hideWhiteBlocker ? "opacity-0" : "opacity-100"
            )}
          />
          <div>Topher Winward</div>
        </Link>
      </div>
      <div>
        <button
          type="button"
          aria-label="Menu"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="px-6 py-4 text-xl"
        >
          <EntypoMenu />
        </button>
      </div>
    </div>
  );
}

function MobileMenuOpen() {
  return (
    <div className="flex flex-col items-center py-3 space-y-1 border-t md:hidden">
      <MobileNavLink href="/">Home</MobileNavLink>
      <MobileNavLink href="/writing">Writing</MobileNavLink>
      <MobileNavLink href="/videos">Videos</MobileNavLink>
    </div>
  );
}

function MobileNavLink({ href, children }) {
  const isActive = useIsNavLinkActive(href);

  return (
    <Link
      href={href}
      passHref
      className={classNames(
        "text-center w-full py-4 text-xl",
        isActive && "font-bold"
      )}
    >
      {children}
    </Link>
  );
}

function DesktopNav() {
  return (
    <nav className="sticky top-0 z-20 font-semibold bg-white border-t-4 border-pink-400 card-shadow-light">
      <div className="container justify-between hidden px-8 mx-auto space-x-2 md:flex md:justify-start md:space-x-16 md:px-32 lg:px-48">
        <DesktopNavLink href="/">Topher Winward</DesktopNavLink>
        <DesktopNavLink href="/writing">Writing</DesktopNavLink>
        <DesktopNavLink href="/videos">Videos</DesktopNavLink>
        {/* <NavLink href="/work">Work</NavLink> */}
      </div>
    </nav>
  );
}

function DesktopNavLink({ href, children }) {
  const pathname = usePathname() ?? "";

  let isActive = false;
  if (href === "/") {
    if (pathname === "/") {
      isActive = true;
    }
  } else {
    isActive = pathname.startsWith(href);
  }

  return (
    <Link
      href={href}
      passHref
      className={classNames(
        "px-3 py-5 hover:border-b-2 hover:border-pink-500",
        isActive && "border-b-2 border-pink-300"
      )}
    >
      {children}
    </Link>
  );
}
